
import buildMessagetransformerMap from './transformers';
import { buildFunctionNames } from './functions';
import { buildEntryPointMap } from './entrypoints';
import { buildExitPointMap } from './exitpoints';
import buildScriptsMap from './scripts';
import buildFunctionMap from './functions';
import { buildOutgoingProfilesMap } from './profiles';
import { buildTransformationMappings, buildMappingNames } from './mappings';
import { updateTransformersForDependencies, updateFunctionsForDependencies } from './dependencies';
import { buildTunnels } from './tunnels';
import { buildPlugins,updateExitpointsForFiletunnels } from './plugins';
/**
 * Build configuration from json object
 * beware that when there is only one object we have nor array
 *      when there are two object we do hav an array.
 *      therfore we check if objects are iterable
 *
 * configuration {
 *      tunnels [
 *          entrypoints []
 *          deliverypoints [
 *              expression
 *              exitpoint
 *              profile [
 *                  transformers [
 *
 *                  ]
 *              ]
 *          ]
 *      ]
 *  }
 * @param configuration
 * @returns {*}
 */
function buildConfiguration(configuration) {
    configuration.version = 'unknown'
    configuration.connected = {};
    if (configuration._attributes) {
        configuration.version = configuration._attributes.version;
        configuration.exportDate = configuration._attributes.generationDate;
    }
    configuration.entrypointmap = buildEntryPointMap(configuration);
    configuration.exitpointmap = buildExitPointMap(configuration);
    configuration.scriptsMap = buildScriptsMap(configuration);
    configuration.functionsMap = buildFunctionMap(configuration);
    configuration.functionNames = buildFunctionNames(configuration);
    configuration.filetunnels = buildPlugins(configuration);
    if (configuration.mappings) {
        configuration.mappings.transformationMappings = buildTransformationMappings(configuration);
        configuration.mappingNames = buildMappingNames(configuration);
    } else {
        //does not exist so create empty structure
        configuration.mappings = {transformationMappings:[]};
        configuration.mappingNames = {};
    }
    configuration.messageTransformerMap = buildMessagetransformerMap(configuration);
    configuration.outgoingProfilesMap = buildOutgoingProfilesMap(configuration);
    
    updateTransformersForDependencies(configuration);
    updateFunctionsForDependencies(configuration);
    updateExitpointsForFiletunnels(configuration);
    // fixOutgoingProfilesMap(configuration);

    configuration.allTunnels = buildTunnels(configuration);
    configuration.allTransformers = buildTranformers(configuration);

    if(configuration.namespaces === undefined) {
        configuration.namespaces = {};
        configuration.namespaces["namespace"] = [];
    }

    return configuration;
}

function buildTranformers(configuration) {
    const transformers = [];
    // console.log(configuration.messageTransformerMap)
    for (const transformer in configuration.messageTransformerMap) {
        // console.log(configuration.messageTransformerMap[transformer]);
        transformers.push(configuration.messageTransformerMap[transformer])
    }
    return transformers;
}



export { buildConfiguration }