import React, { useState } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core'
import Entrypoint from "./Entrypoint.js"
// import Tunnel from "./Tunnel";
// import { DatabaseAdd } from "react-bootstrap-icons";
// import Variables from "./Variables";
// import BadgeExtended from "../react/BadgeExtended";

library.add(faCaretRight);

export default function Entrypoints({ data, expand, showtunnel }) {
  const [entrypointVisible, setEntrypointVisiblity] = useState(false);
  if (data === undefined) data = []

  return (
    <div className='mb-1' hidden={!(data.length > 0)}>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${entrypointVisible ? "active" : ""}`}
        onClick={(e) => {
          setEntrypointVisiblity((v) => !v);
        }} />
      <Badge className='badge-fixed' bg="secondary" >{data.length} Entrypoints</Badge>
      <Collapse in={entrypointVisible}>
        <div className=' ms-4'>
          {data.map((entrypoint) => (
            <div key={entrypoint.code}>
              <div className='row  ${varVisible ? "active" : "" }'>
                <Entrypoint data={entrypoint} expand={expand} showtunnel={showtunnel} />
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  )
}