import React, { useState } from "react";
import { Badge, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Tunnel from "./Tunnel";

library.add(faCaretRight);

export default function Tunnels({ data }) {
  const [itemVisible, setItemVisiblity] = useState(false);
  return (
    <div className='mb-1'>
      <FontAwesomeIcon icon={faCaretRight} className={`d-inline d-tree-toggler ${itemVisible ? "active" : ""}`}
        onClick={(e) => {
          setItemVisiblity((v) => !v);
        }} />
      <Badge className='badge-fixed' bg="secondary"
      >{data.length} Tunnels</Badge>
      {data.map((tunnel) => (
        <Collapse key={tunnel.code} in={itemVisible}>
          <div className='row  ms-4'>
            <Tunnel data={tunnel} />
          </div>
        </Collapse>
      ))}
    </div>
  )
}
