import React from 'react';
import Badge from 'react-bootstrap/Badge';
import classNames from 'classnames';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Popover } from 'react-bootstrap';

const BadgeExtended = props => {
    const { className, size, tooltip, action, copytext, popover, ...attr } = props;

    const classes = classNames(
        className,
        size && `badge-${size}`
    );

    function getBadge() {
        return <Badge className={classes} {...attr}
            onClick={
                (e) => {
                    //check what action needs to be done
                    if (action === 'copy' || copytext) {
                        if (copytext) {
                            navigator.clipboard.writeText(copytext);
                        } else {
                            navigator.clipboard.writeText(e.target.innerText);
                        }

                    }
                }
            }>
            {props.children}
        </Badge>
    }
    if (tooltip) {
        return <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
                <Tooltip {...props}>
                    {tooltip}
                </Tooltip>
            )}
            placement="top">
            {getBadge()}
        </OverlayTrigger>;
    } else if (popover) {
        return <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            placement="bottom"
            overlay={(props) => (
                <Popover {...props} >
                    <Popover.Header as="h3">{popover.header} </Popover.Header>
                    <Popover.Body >{popover.body}</Popover.Body>
                </Popover>
            )}>
            {getBadge()}
        </OverlayTrigger>;
    } else {
        return getBadge()
    }
};

export default BadgeExtended;